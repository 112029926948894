import { Component } from '@angular/core';

@Component({
  selector: 'ume-restricted-access',
  templateUrl: './restricted-access.component.html',
  styleUrls: ['./restricted-access.component.scss'],
  standalone: true,
})
export class RestrictedAccessComponent {
  constructor() {}
}
