@if (notification) {
  @switch (notification.type) {
    @case (notificationType.SUCCESS) {
      <ng-container [ngTemplateOutlet]="success"></ng-container>
    }
    @case (notificationType.INFO) {
      <ng-container [ngTemplateOutlet]="info"></ng-container>
    }
    @case (notificationType.WARN) {
      <ng-container [ngTemplateOutlet]="warning"></ng-container>
    }
    @case (notificationType.WARN_ONLY) {
      <ng-container [ngTemplateOutlet]="warningOnly"></ng-container>
    }
    @case (notificationType.ERROR) {
      <ng-container [ngTemplateOutlet]="error"></ng-container>
    }
    @case (notificationType.ERROR_ONLY) {
      <ng-container [ngTemplateOutlet]="errorOnly"></ng-container>
    }
    @default {
      <ng-container [ngTemplateOutlet]="success"></ng-container>
    }
  }
}

<ng-template #success>
  <div class="alert success">
    <button mat-icon-button class="close" (click)="hide()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="alert__text">
      <strong>{{ notificationTitle }}</strong>
      <div class="err-message" [innerHTML]="notification?.message"></div>
    </div>
  </div>
</ng-template>

<ng-template #info>
  <div class="alert info">
    <button mat-icon-button class="close" (click)="hide()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="alert__text">
      <p class="alert-text-title">
        <mat-icon>info</mat-icon>
        <strong>{{ notificationTitle }}</strong>
      </p>
      <div class="err-message" [innerHTML]="notification?.message"></div>
    </div>
  </div>
</ng-template>

<ng-template #warning>
  <div class="alert warning">
    <button mat-icon-button class="close" (click)="hide()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="alert-text">
      <p class="alert-text-title">
        <mat-icon>info</mat-icon>
        <strong>{{ notificationTitle }}</strong>
      </p>
      <p class="contact-ume">We are sorry, something went wrong. Please try again or contact
        <a
          href="mailto:support@ume.solutions?body=%0A%0A%0A========Technical information
          (please don’t modify the text below):========%0A{{notification?.message}}">
          support&#64;ume.solutions
        </a>
      </p>
      <div class="err-message" [innerHTML]="notification?.message"></div>
    </div>
  </div>
</ng-template>

<ng-template #warningOnly>
  <div class="alert warning">
    <button mat-icon-button class="close" (click)="hide()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="alert-text">
      <p class="alert-text-title">
        <mat-icon>info</mat-icon>
        <strong>{{ notificationTitle }}</strong>
      </p>
      <div class="err-message" [innerHTML]="notification?.message"></div>
    </div>
  </div>
</ng-template>

<ng-template #error>
  <div class="alert error">
    <button mat-icon-button class="close" (click)="hide()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="alert-text">
      <p class="alert-text-title">
        <mat-icon>warning</mat-icon>
        <strong>{{ notificationTitle }}</strong>
      </p>
      <p class="contact-ume">We are sorry, something went wrong. Please try again <br> or contact
        <a
          href="mailto:support@ume.solutions?body=%0A%0A%0A========Technical information
          (please don’t modify the text below):========%0A{{notification?.message}}">
          support&#64;ume.solutions
        </a>
      </p>
      <div class="err-message" [innerHTML]="notification?.message"></div>
    </div>
  </div>
</ng-template>

<ng-template #errorOnly>
  <div class="alert error">
    <button mat-icon-button class="close" (click)="hide()">
      <mat-icon>clear</mat-icon>
    </button>
    <div class="alert-text">
      <p class="alert-text-title">
        <mat-icon>warning</mat-icon>
        <strong>{{ notificationTitle }}</strong>
      </p>
      <div class="err-message" [innerHTML]="notification?.message"></div>
    </div>
  </div>
</ng-template>



