import { ICompanyInfo } from '@core/models/app.model';
import { IProfileBR } from '@core/models/company.model';
import {
  ActionStatus,
  ControlledViews,
  ICounterpartyOnboardingInfo,
  ViewCode,
} from '@core/models/onboarding.model';
import { IScoringThresholds } from '@core/models/questionnaire.model';
import { createStore, select, withProps } from '@ngneat/elf';
import { shareReplay } from 'rxjs';

interface CompanyProps {
  controlledStatus: ICounterpartyOnboardingInfo | null;
  brId: string | null;
  brProfile: IProfileBR | null;
  brThreshold: IScoringThresholds;
  selectedDistributor: ICompanyInfo;
}

const COMPANY_STORE = createStore(
  { name: 'company' },
  withProps<CompanyProps>({
    controlledStatus: null,
    brId: null,
    brProfile: null,
    brThreshold: {} as IScoringThresholds,
    selectedDistributor: {} as ICompanyInfo,
  })
);

/**
 * Store observables
 */

export const controlledStatus$ = COMPANY_STORE.pipe(
  select((state) => state.controlledStatus),
  shareReplay({ refCount: true })
);

export const brId$ = COMPANY_STORE.pipe(
  select((state) => state.brId),
  shareReplay({ refCount: true })
);

export const brProfile$ = COMPANY_STORE.pipe(
  select((state) => state.brProfile),
  shareReplay({ refCount: true })
);

export const brThreshold$ = COMPANY_STORE.pipe(
  select((state) => state.brThreshold),
  shareReplay({ refCount: true })
);

/**
 * Storing operations with state
 */

export function setControlledStatus(controlledStatus: CompanyProps['controlledStatus']): void {
  COMPANY_STORE.update((state: CompanyProps) => ({
    ...state,
    controlledStatus,
  }));
}

export function setBrId(brId: CompanyProps['brId']): void {
  COMPANY_STORE.update((state: CompanyProps) => ({
    ...state,
    brId,
  }));
}

export function setBrProfile(brProfile: CompanyProps['brProfile']): void {
  COMPANY_STORE.update((state: CompanyProps) => ({
    ...state,
    brProfile,
  }));
}

export function setBrThreshold(brThreshold: CompanyProps['brThreshold']): void {
  COMPANY_STORE.update((state: CompanyProps) => ({
    ...state,
    brThreshold,
  }));
}

export function setSelectedDistributor(
  selectedDistributor: CompanyProps['selectedDistributor']
): void {
  COMPANY_STORE.update((state: CompanyProps) => ({
    ...state,
    selectedDistributor,
  }));
}

export function resetCompanyStore(): void {
  COMPANY_STORE.reset();
}

export function getCompanyStoreValue(): CompanyProps {
  return COMPANY_STORE.getValue();
}

export function getBrId(): string {
  return COMPANY_STORE.getValue().brId || '';
}

export function getPageActions(viewCode: ViewCode): ActionStatus[] {
  return (
    getCompanyStoreValue().controlledStatus?.detailsViews.find(
      (view: ControlledViews) => view.viewCode === viewCode && !view.disabledReason
    )?.actions || []
  );
}

export function getActionByName(viewCode: ViewCode, actionName: ActionStatus): boolean {
  return getPageActions(viewCode).some((action: ActionStatus) => action === actionName);
}

export function getOnHoldCondition(): boolean {
  return COMPANY_STORE.getValue().controlledStatus?.onHold || false;
}

export function getOnboardingInfo(): ICounterpartyOnboardingInfo | null {
  return COMPANY_STORE.getValue().controlledStatus;
}
