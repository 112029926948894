<header
  id="header"
  class="header-main">
  <a
    [routerLink]="homepage"
    id="logo">
    <img
      [ngSrc]="'/assets/images/umeLogo.svg'"
      width="48"
      height="48"
      priority="true"
      alt="UME" />
  </a>
  <nav
    id="topNav"
    role="navigation">
    <ng-content></ng-content>
  </nav>
</header>
