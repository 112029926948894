import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppInterceptor } from '@core/interceptors/app.interceptor';
import { ErrorHandlingInterceptor } from '@core/interceptors/error-handling.interceptor';
import { MY_FORMATS } from '@shared/constants/ume-constants';
import { ProgressIndicatorsModule } from '@shared/progress-indicators';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    /**
     * Build error:
     * ERROR in Error during template compile of 'AppModule'
     * Function calls are not supported in decorators but 'cookies' was called.
     * =====
     * Handled manually in the AppInterceptor until further notice.
     */
    // HttpClientXsrfModule.withOptions({
    //   cookieName: cookies.COOKIES.COOKIE_NAME + environment.XSRF_PREFIX,
    //   headerName: cookies.COOKIES.HEADER_NAME,
    // }),
    MatNativeDateModule,
    AppRoutingModule,
    ProgressIndicatorsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptor,
      multi: true,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
