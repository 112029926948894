import { Injectable } from '@angular/core';
import { CounterpartyScore, QuestionnaireValidityStatus } from '@core/models/questionnaire.model';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScoringMediatorService {
  private _scoreSubject = new ReplaySubject<CounterpartyScore>();
  private _questionnaireStatus = new ReplaySubject<QuestionnaireValidityStatus>();

  public score$ = this._scoreSubject.asObservable();
  public questionnaireStatus$ = this._questionnaireStatus.asObservable();

  setScore(score: CounterpartyScore): void {
    return this._scoreSubject.next(score);
  }

  setQuestionnaireStatus(status: QuestionnaireValidityStatus): void {
    return this._questionnaireStatus.next(status);
  }
}
