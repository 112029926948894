import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationStart, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { INavigationItem, ItemInfo, MENU_LABEL } from '@core/models/navigation-item.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

import { setRestrictedAccessRoute } from '../guards/navigationLink.guard';
import { CompanyTypeBasedRoutePipe } from '../pipes/company-type-based-route.pipe';
import { MainNavMenuPanelComponent } from './main-nav-menu-panel/main-nav-menu-panel.component';

@UntilDestroy()
@Component({
  selector: 'ume-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    CompanyTypeBasedRoutePipe,
    MainNavMenuPanelComponent,
  ],
})
export class NavigationComponent implements OnInit {
  @Input()
  set items(items: INavigationItem[] | null) {
    if (items) {
      this._items = items;
    }
  }

  get items(): INavigationItem[] {
    return this._items;
  }

  @Output() triggerCustomEvent = new EventEmitter();

  private _items: INavigationItem[] = [];
  private _currentRoute: string;

  public averageMenuMagicNumber = 154;
  public windowWidth = 560;
  public premiumLabel = MENU_LABEL.PREMIUM;

  @HostListener('window:resize')
  detectWindowSize(): void {
    this.windowWidth = window.innerWidth;
  }

  constructor(
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _elRef: ElementRef<HTMLElement>
  ) {
    this._currentRoute = this._router.url;
  }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event: any) => event instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        const { url } = event;
        this._currentRoute = url;
      });
  }

  public get showMatMenu(): boolean {
    this._cdr.markForCheck();
    return this._elRef.nativeElement.clientWidth < this.items.length * this.averageMenuMagicNumber;
  }

  public handleClick(item: ItemInfo, event: MouseEvent): void {
    if (item.payment) {
      event.preventDefault();
      this._router.navigate(['/', 'plans', 'comparison']);
      return;
    }

    if (item.disabled) {
      event.preventDefault();
      this._redirectToRestrictedUserRights(item);
    }

    if (item.customEvent) {
      if (item.disabled || item.disabledReason) {
        this._redirectToRestrictedUserRights(item);
        return;
      }

      this.handleCustomEvent();
    }
  }

  public handleCustomEvent(): void {
    this.triggerCustomEvent.emit();
  }

  private _redirectToRestrictedUserRights(item: ItemInfo): void {
    const label: string | string[] = setRestrictedAccessRoute(item.menuLabel);
    if (!label || label === '') {
      this._router.navigate(['/', 'restricted-user-rights']);
      return;
    }

    this._router.navigate([
      '/',
      ...(Array.isArray(label) ? label : [label]),
      'restricted-user-rights',
    ]);
    return;
  }
}
