import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyTypeBasedRoute',
  standalone: true,
})
export class CompanyTypeBasedRoutePipe implements PipeTransform {
  public transform(path: string): string {
    if (!path) {
      return '/';
    }

    return '/' + path;
  }
}
