<div class="restrict-page">
  <div class="restricted-content">
    <picture>
      <img src="/assets/svg/icon-access-denied.svg" alt="restricted access" height="136" width="184">
    </picture>
    <h2>Restricted access</h2>
    <p>You do not have the rights required to access this page.</p>
    <p>Please contact your admin for further information.</p>
  </div>
</div>
