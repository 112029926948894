import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '@auth/repository';
import { sessionUser$ } from '@auth/repository/auth.repository';
import { ICompanyLookupBean } from '@core/models/app.model';
import { ItemInfo } from '@core/models/navigation-item.model';
import { IAuthenticUser, IUserProfile } from '@core/models/user.model';
import { PermissionService } from '@core/permissions/permission.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DIALOG_SIZE } from '@shared/constants/ume-constants';
import { SelectCompanyDialogComponent } from '@shared/dialogs/select-company-dialog/select-company-dialog.component';
import { CompanyTypePipe } from '@shared/pipes/company-type.pipe';
import { UserService } from '@user/repository';
import { profile$ } from '@user/repository/user.repository';
import { findIndex } from 'lodash/fp';
import { filter } from 'rxjs/operators';

import { getProviderItems, getUserItems } from '../repository/navigation-items.repository';

@UntilDestroy()
@Component({
  selector: 'ume-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    CompanyTypePipe,
    RouterLink,
  ],
})
export class ProfileMenuComponent implements OnInit {
  public currentUser!: IAuthenticUser;
  public userCompaniesToShow: ICompanyLookupBean[] = [];
  public selectedCompany!: ICompanyLookupBean;
  public menuItems: ItemInfo[] = [];
  public firstName = '';
  public fullName = '';
  public photoURL: string | null = null;
  public picLoadError = false;
  public showThreshold = 5;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private permissions: PermissionService
  ) {}

  ngOnInit(): void {
    this.router.onSameUrlNavigation = 'reload';
    this.createNavItems();

    // load data for the first time
    this.userService.getMyProfile().pipe(untilDestroyed(this)).subscribe();

    profile$
      .pipe(filter(Boolean), untilDestroyed(this))
      // @ts-ignore
      .subscribe(({ firstName, lastName, picture }: IUserProfile) => {
        this.firstName = firstName;
        this.fullName = firstName + ' ' + lastName;
        this.photoURL = Boolean(picture) ? picture : null;
      });

    sessionUser$
      .pipe(
        filter((user: IAuthenticUser) => Boolean(user.selectedCompany)),
        untilDestroyed(this)
      )
      // @ts-ignore
      .subscribe((user: IAuthenticUser) => {
        this.currentUser = user;
        // @ts-ignore
        this.selectedCompany = user.selectedCompany;
        this.userCompaniesToShow = [...user.linkedCompanies];

        this.firstName = user.firstName;
        this.fullName = user.firstName + ' ' + user.lastName;

        this._prepareCompaniesToShow();
        this.createNavItems();
      });
  }

  public changeCompany(company: ICompanyLookupBean): void {
    if (company.guid !== this.currentUser.selectedCompany?.guid) {
      this.authService.redirectUserToCompany(company);
      company.isNew = false;
    }
  }

  public showMoreCompanies(): void {
    this.dialog
      .open(SelectCompanyDialogComponent, {
        data: {
          label: 'Choose your current company',
          showClose: true,
        },
        width: DIALOG_SIZE.MODAL_SM_UP,
        minHeight: '320px',
      })
      .afterClosed()
      .pipe(filter(Boolean), untilDestroyed(this))
      // @ts-ignore
      .subscribe(
        (company: ICompanyLookupBean) => {
          this.changeCompany(company);
        },
        (_) => {}
      );
  }

  public loadError(): void {
    this.picLoadError = true;
  }

  public logOut(): void {
    this.authService.logout();
  }

  private _prepareCompaniesToShow(): void {
    // show currently selected company on top of the list
    const selectedIndex = findIndex({ guid: this.selectedCompany.guid }, this.userCompaniesToShow);

    this.userCompaniesToShow.splice(selectedIndex, 1);
    this.userCompaniesToShow.unshift(this.selectedCompany);

    if (this.userCompaniesToShow.length > this.showThreshold) {
      this.userCompaniesToShow.splice(this.showThreshold);
    }
  }

  private createNavItems(): void {
    this.permissions.isUmeOps()
      ? (this.menuItems = getProviderItems())
      : (this.menuItems = getUserItems());
  }
}
