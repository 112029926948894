import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { AuthService } from '@auth/repository';
import {
  hasServiceSync,
  isLoggedIn$,
  userSelectedCompany$,
} from '@auth/repository/auth.repository';
import { ICompanyLookupBean, TermAndCondition } from '@core/models/app.model';
import { INavigationItem, ItemInfo } from '@core/models/navigation-item.model';
import { PermissionService } from '@core/permissions/permission.service';
import { UME_SERVICE } from '@core/service-subscriptions/ume-services';
import { environment } from '@env/environment';
import { downloadFileAction } from '@helpers/download-file';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DIALOG_SIZE, HOME_PAGE } from '@shared/constants/ume-constants';
import { UserService } from '@user/repository';
import { EMPTY, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { INotification } from 'src/app/routes/dashboard/models';
import { notifications$ } from 'src/app/routes/dashboard/repository/dashboard.repository';
import { DashboardService } from 'src/app/routes/dashboard/repository/dashboard.service';

import { NewUserSignupDialogComponent } from '../../routes/dashboard/components/new-user-signup-dialog/new-user-signup-dialog.component';
import { MainNavWrapComponent } from '../main-nav-wrap/main-nav-wrap.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { CompanyTypeBasedRoutePipe } from '../pipes/company-type-based-route.pipe';
import { ProfileMenuComponent } from '../profile-menu/profile-menu.component';
import { appNavigationItems$ } from '../repository/navigation-items.repository';
import { UserNotificationsComponent } from '../user-notifications/user-notifications.component';

@UntilDestroy()
@Component({
  selector: 'ume-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    MatProgressBar,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    RouterLink,
    CompanyTypeBasedRoutePipe,
    ProfileMenuComponent,
    MainNavWrapComponent,
    NavigationComponent,
    UserNotificationsComponent,
  ],
})
export class BaseLayoutComponent implements OnInit {
  public navigationItems: INavigationItem[] | null = null;
  public helpItems: ItemInfo[] = getHelpItems();
  public isVirtualPremium: boolean = this.permissions.isVirtualPremiumClient();
  public $isLoggedIn: Observable<boolean> = isLoggedIn$;
  public termsAndConditions$: Observable<TermAndCondition[]> = userSelectedCompany$.pipe(
    distinctUntilChanged(),
    filter((res: ICompanyLookupBean | undefined) => Boolean(res)),
    switchMap((c) => {
      return c ? this.autService.getCompanyApplicableTerms(c.guid) : EMPTY;
    })
  );
  public notifications$: Observable<INotification> = notifications$;

  public loading$ = this.router.events.pipe(
    filter(
      (e) =>
        e instanceof NavigationStart ||
        e instanceof NavigationEnd ||
        e instanceof NavigationError ||
        e instanceof NavigationCancel
    ),
    map((e) => e instanceof NavigationStart)
  );

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private autService: AuthService,
    private userService: UserService,
    private permissions: PermissionService,
    private dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    // app wide subscription
    appNavigationItems$.pipe(untilDestroyed(this)).subscribe((items) => {
      this.navigationItems = items;
    });

    if (!hasServiceSync(UME_SERVICE.PROVIDER) && !this.router.url.includes(HOME_PAGE)) {
      this.dashboardService
        .getNotifications()
        .pipe(
          catchError(() => EMPTY),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  public get notUmeOps(): boolean {
    return !this.permissions.isUmeOps();
  }

  public downloadTerms(term: { name: string; guid: string }): void {
    this.autService
      .downloadTerms(term)
      .pipe(untilDestroyed(this))
      .subscribe(
        ({ body, contentDisposition }: { body: ArrayBuffer; contentDisposition: string }) => {
          downloadFileAction(body, contentDisposition, term.name, 'pdf');
        }
      );
  }

  public openSaml(target = environment.qlikApp): void {
    this.userService
      .setCookiesToCompany()
      .pipe(untilDestroyed(this))
      .subscribe(() => window.open(`${environment.qlikUrl}/sense/app/${target}`, '_blank'));
  }

  public openMasterAnalytics(): void {
    this.openSaml(environment.adminQlikApp);
  }

  public welcomeGuide(): void {
    this.dialog.open(NewUserSignupDialogComponent, {
      width: DIALOG_SIZE.MODAL_MD,
      autoFocus: true,
    });
  }

  public handleCustomEvent(): void {
    this.permissions.isUmeOps() ? this.openMasterAnalytics() : this.openSaml();
  }
}

export function getHelpItems(): ItemInfo[] {
  return [
    {
      label: 'ABOUT_UME',
      menuLabel: 'ABOUT',
      name: 'About ume',
      link: 'https://www.ume.solutions/',
      target: '_blank',
      external: true,
      disabled: false,
      payment: false,
      icon: 'error_outline',
    },
    {
      label: 'FAQ',
      menuLabel: 'ABOUT',
      name: 'FAQ',
      link: '/about/faq',
      disabled: false,
      payment: false,
      icon: 'help_outline',
    },
  ];
}
