import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { hasServiceSync, userSelectedCompany$ } from '@auth/repository/auth.repository';
import { UME_SERVICE } from '@core/service-subscriptions/ume-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HOME_PAGE, PROVIDER } from '@shared/constants/ume-constants';

@UntilDestroy()
@Component({
  selector: 'ume-main-nav-wrap',
  styleUrls: ['main-nav-wrap.component.scss'],
  templateUrl: 'main-nav-wrap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterLink, NgOptimizedImage],
})
export class MainNavWrapComponent implements OnInit {
  public homepage?: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    userSelectedCompany$.pipe(untilDestroyed(this)).subscribe(() => {
      this.homepage = hasServiceSync(UME_SERVICE.PROVIDER)
        ? `${PROVIDER + '/' + HOME_PAGE}`
        : HOME_PAGE;
      this.cdr.markForCheck();
    });
  }
}
